(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('canceropoleGsoApp')
        .constant('VERSION', "1.2.18")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_DATE', "2025-1-24 09:43:49")
;
})();
